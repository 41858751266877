var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-text", { staticClass: "pa-1 title text-center" }, [
            _vm._v(_vm._s(_vm.$t("message.shifts.shift"))),
          ]),
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "touch",
                  rawName: "v-touch",
                  value: {
                    left: _vm.handleSwipeLeftEvent,
                    right: _vm.handleSwipeRightEvent,
                  },
                  expression:
                    "{\n        left: handleSwipeLeftEvent,\n        right: handleSwipeRightEvent\n      }",
                },
              ],
              staticClass: "grey lighten-2 text-center ma-1 pa-0",
            },
            [
              _c("v-col", { staticClass: "pa-1", attrs: { cols: "4" } }, [
                _vm._v(_vm._s(_vm.previousMonth)),
              ]),
              _c(
                "v-col",
                { staticClass: "pa-1 font-weight-bold", attrs: { cols: "4" } },
                [_vm._v(_vm._s(_vm.currentMonth))]
              ),
              _c("v-col", { staticClass: "pa-1", attrs: { cols: "4" } }, [
                _vm._v(_vm._s(_vm.nextMonth)),
              ]),
            ],
            1
          ),
          _vm.showLoader
            ? _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                _vm._l(5, function (n) {
                  return _c(
                    "v-col",
                    { key: n, attrs: { cols: "12" } },
                    [
                      _c(
                        "v-sheet",
                        { staticClass: "my-2 px-2" },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: {
                              type: "list-item-avatar-three-line",
                              height: "75",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm.listOfShifts && _vm.listOfShifts.length > 0
            ? _c(
                "v-card-text",
                { staticClass: "py-1 px-2" },
                _vm._l(_vm.listOfShifts, function (element, index) {
                  return _c(
                    "v-row",
                    { key: index, staticClass: "px-1" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-1 text-center",
                          attrs: { cols: "2" },
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "pa-0",
                              attrs: { flat: "", width: "100" },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-0 font-weight-medium text-h6",
                                },
                                [_vm._v(_vm._s(element.date))]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-0 subtitle-2 font-weight-medium",
                                },
                                [_vm._v(_vm._s(element.day))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-1", attrs: { cols: "10" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "mx-auto", attrs: { outlined: "" } },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "pa-1 subtitle-1 font-weight-medium",
                                },
                                [
                                  _vm._v(" " + _vm._s(element.time) + " "),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showDetails(
                                            index,
                                            element.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            element.active
                                              ? "mdi-chevron-up"
                                              : "mdi-chevron-down"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-card-subtitle", { staticClass: "pa-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(element.text) +
                                    " " +
                                    _vm._s(element.project_name) +
                                    " "
                                ),
                              ]),
                              _c("v-expand-transition", [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: element.active,
                                        expression: "element.active",
                                      },
                                    ],
                                  },
                                  [
                                    _c("v-divider"),
                                    _c(
                                      "v-card-text",
                                      { staticClass: "pa-1" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "align_left" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(element.description)
                                            ),
                                          ]
                                        ),
                                        element.documents &&
                                        element.documents.length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "align_left",
                                                staticStyle: {
                                                  "font-size": "14px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "message.shifts.documents"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._l(
                                          element.documents,
                                          function (element) {
                                            return _c(
                                              "div",
                                              {
                                                key: element.url,
                                                staticClass: "align_left",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: element.url,
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(element.name))]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        (!element.description &&
                                          !element.documents) ||
                                        (!element.description &&
                                          element.documents.length === 0)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "align_left",
                                                staticStyle: {
                                                  "font-style": "italic",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "message.shifts.not_found"
                                                      )
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _c(
                "v-alert",
                {
                  staticClass: "mt-2 mx-1",
                  attrs: { prominent: "", text: "", type: "info" },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow" }, [
                        _vm._v(_vm._s(_vm.$t("message.common.noResults"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }